/* Heading 1 */
h1 {
  font-size: 2.8em; /* Adjust as needed */
  font-weight: bold;
  margin-bottom: 0.5em; /* Adjust spacing as needed */
}

/* Heading 2 */
h2 {
  font-size: 1.5em; /* Adjust as needed */
  font-weight: bold;
  margin-bottom: 0.5em; /* Adjust spacing as needed */
}

/* Heading 3 */
h3 {
  font-size: 1.05em; /* Adjust as needed */
  font-weight: bold;
  margin-bottom: 0.5em; /* Adjust spacing as needed */
}

/* Heading 4 */
h4 {
  font-size: 1em; /* Adjust as needed */
  font-weight: bold;
  margin-bottom: 2em; /* Adjust spacing as needed */
  color: rgba(0, 0, 0, 0.5); /* 50% transparent black */
}

.link {
  text-decoration: none;
}

a {
  color: inherit; /* Inherits color from parent element */
  text-decoration: none; /* Removes underline */
  cursor: auto; /* Resets cursor to default */
}


/* Paragraph */
p {
  font-size: 1em; /* Adjust as needed */
  line-height: 2; /* Adjust line height as needed */
  margin-bottom: 1em;
}

b {
  font-size: 1em;
  line-height: 0em;
  margin-bottom:0em;
  margin-top:1em;
}

.bottomlined {
    position: absolute;
    bottom: 0;
}

.container {
  margin-left: 10%;
  margin-right: 10%;
  height: 84%;
  position: absolute;
  margin-top: 4%; /* Adjusted to desired top margin */
  margin-bottom: 4%; /* Adjusted to desired bottom margin */
  overflow: hidden;
}

.container_mobile {
  margin-left: 10%;
  margin-right: 10%;
  height: 84%;
  margin-top: 4%; /* Adjusted to desired top margin */
  margin-bottom: 4%; /* Adjusted to desired bottom margin */
  overflow: hidden;
}

.column_left {
  width:25%;
  height: 84%;
  position: fixed;
  overflow-y: auto;
}

.column_right {
    width: 55%;
    height: 100%;
    overflow-y: scroll; /* Always show scrollbar */
    position: relative;
    margin-left: 45%;
    margin-top: 2.5%;
    scrollbar-width: thin; /* Show a thin scrollbar */
    scrollbar-color: transparent transparent; /* Hide scrollbar track */
}

/* Custom styling for WebKit browsers (e.g., Chrome, Safari) */
.column_right::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* Custom styling for the scrollbar thumb */
.column_right::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color of the scrollbar thumb */
}

.about {
  font-size: 1em; /* Adjust as needed */
  line-height: 2.4em; /* Adjust line height as needed */
  margin-bottom: 1.5em;
  padding-left: 1em; /* Add padding to create space around the content */
  padding-right: 1em; /* Add padding to create space around the content */
}

.intro {
  padding-left: 1em; /* Add padding to create space around the content */
  padding-right: 1em; /* Add padding to create space around the content */
}

.bio {
  font-size: 0.6em; /* Adjust as needed */
  text-align: center; /* Corrected property name */
  line-height: 2.4em; /* Adjust line height as needed */
  margin-bottom: 6em;
  padding-left: 5em; /* Add padding to create space around the content */
  padding-right: 5em; /* Add padding to create space around the content */
}

.experience {
  font-size: 1em; /* Adjust as needed */
  line-height: 2; /* Adjust line height as needed */
  margin-top: 2em; /* Adjust spacing as needed */
  margin-bottom: 4em;
}

.projects {
  font-size: 1em; /* Adjust as needed */
  line-height: 2; /* Adjust line height as needed */
  margin-top: 2em; /* Adjust spacing as needed */
  margin-bottom: 4em;
}

.publications {
  font-size: 1em; /* Adjust as needed */
  line-height: 2; /* Adjust line height as needed */
  margin-top: 2em; /* Adjust spacing as needed */
  margin-bottom: 2em;
}

.highlighted {
  color: rgba(0, 0, 0, 1); /* 50% transparent black */
}

.highlighted_mob {
  color: rgba(0, 0, 0, 1); /* 50% transparent black */
  text-align: left;
}

.transp {
  color: rgba(0, 0, 0,0.5); /* 50% transparent black */
  text-align: left
}

.color-icon svg {
  fill: blue; /* Change color to blue */
}


.top-banner {
    justify-content: space-between;
    background-color: white;
    position: fixed;
    padding-left:12%;
    padding-bottom: 3%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Adjust z-index as needed */
}


.column {
  float: right;
  text-align: left;
  width: 50%;
}

.columnleft {
  float: left;
  width: 50%;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
  