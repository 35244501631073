  /* ExperienceComponent.css */

.containerexp {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
}

.containerexp_mob {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
}


.containerexp:hover {
  background-color: lightgray; /* Change the background color on hover */
}

.left-columnexp {
  flex: 0 0 20%; /* Adjust the width of the left column as needed */
}

.right-columnexp {
  flex: 0 0 75%; /* Adjust the width of the right column as needed */
}

.imageexp {
  width: 100%; /* Make the image fill the container */
}

.rounded-box {
  display: inline-block;
  border-radius: 15px; /* Adjust the value to control the roundness of the corners */
  border: 1px solid #ccc; /* Border color */
  padding-top: 2px; /* Adjust the padding as needed */
  padding-right:10px; /* Adjust the padding as needed */
  padding-left: 10px; /* Adjust the padding as needed */
  padding-bottom: 2px; /* Adjust the padding as needed */
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.75em;
  background-color: white;
white-space: nowrap; /* Prevent line breaks within the span */
  page-break-inside: avoid; /* Prevent the element from being split across pages */
}
.blockexp-container {
  padding: 1.2em;
  position: relative;
  margin-bottom: 1em;
}

.blockexp-container_mob {
  padding: 1.2em;
  position: relative;
  margin-bottom: 1em;
}

.blockexp {
  font-size: 0.9em;
}

.blockexp-container:hover {
  background-color: lightgray;
  border-radius: 20px;
}

.blockexp-container:hover::after {
  content: url('./link.svg');
  position: absolute;
  top: 1em;
  right: 1em;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.svgicon {
  widht: 12px;
  height: 12px;
}